/**
 * Gallo_Gateway
 * Class for handling Age Disclaimers. Originally built by
 * Affinitive's Rob Marscher (rmarscher@beaffinitive.com).
 */
Gallo_Gateway = function (options) {
    this.settings = {
        url: fpglobals.baseurl + 'agegate',		// gateway url
        cookie: 'age-gate',				// cookie name
        cookieDays: 1						// cookie expiry in days
    };
    
    if (options) {
        jQuery.extend(this.settings, options);
    }
    ;
};

Gallo_Gateway.prototype = {
    init: function () {
        var gateway = this;
        if (!this.check()) {
            jQuery(document).ready(function () {
                gateway._showPopup();
            });
            //attach events to close popup
            /*$(document).on("click", ".btn-too-young", function (event) {
                gateway._showTooYoung();
            });*/
            $(document).on("click", ".btn-enter", function (event) {
                gateway.ok();
            });
            return true;
        }
        return false;
    },
    extendCookie: function () {
        var date = new Date();
        date.setTime(date.getTime() + (this.settings.cookieDays * 86400000));
        var cookieValue = this.settings.cookie + "=true; expires=" + date.toGMTString() + "; path=/;";
        if (fpglobals.cookieDomain) {
            cookieValue += " domain=" + fpglobals.cookieDomain + ";";
        }
        document.cookie = cookieValue;
    },
    check: function () {
        var url = window.location.pathname;
        //first check if on legal page, if on legal page no popup
        if (url.indexOf("/legal") >= 0) {
            return true;
        }
        if (document.cookie.length > 0) {
            var start, end;
            start = document.cookie.indexOf(this.settings.cookie + "=");
            if (start != -1) {
                start = start + this.settings.cookie.length + 1;
                end = document.cookie.indexOf(";", start);
                if (end == -1) {
                    end = document.cookie.length;
                }
                return unescape(document.cookie.substring(start, end));
            }
        }
        return false;
    },
    _showPopup: function () {
        $(document.body).css("overflow", "hidden");
        this.popupContainer = jQuery('<div></div>')
            .addClass('overlay-container')
            .appendTo(document.body)
            .fadeIn();
        this.popup = jQuery('<div></div>')
            .addClass('overlay-content')
            .load(this.settings.url)
            .appendTo(document.body)
            .fadeIn();
        
    },
    _hidePopup: function () {
        $(document.body).css("overflow", "auto");
        this.popupContainer.fadeOut('fast', function () {
            jQuery('.overlay-container').remove();
        });
        this.popup.fadeOut('fast', function () {
            jQuery('.overlay-content').remove();
        });
    },
    //Clicked on NO Not 21
    _showTooYoung: function () {
        var form = $('.ageGateForm');
        /*var tooYoungMsg = $('.tooYoungMsg');
        form.fadeOut(function () {
            tooYoungMsg.fadeIn();
        });*/
    },
    //Clicked on YES 21
    ok: function () {
        this.extendCookie();
        this._hidePopup();
    }
};

var fpglobals = {
    baseurl: '/',
    cookeDomain: '.nighttraincoldbrewcoffeespirit.com'
};

fpglobals.gateway = new Gallo_Gateway();

fpglobals.gateway.init();

